<template>
  <PageCard
    pageName="FIN Digital channels"
    :iconClass="['fa', 'icon-screen-smartphone', 'fa-lg']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div
        :class="
          partnerSite ? ['homepage-content partner-site'] : ['homepage-content']
        "
      >
        <section class="  solution-section">
          <div class="">
            <div class="section container-margin" id="1">
              <FeatureDetailsCard
                title="FIN Digital Channels"
                description1="FIN Digital Channels focuses on retail mobile &amp; online banking delivering innovative metrics on vendor and FI performance."
                description2="The solution produces comparative vendor dashboards on market share, share accretion, churn rates, and much more.  Institution dashboards cover every U.S. institution segmented by FI type, asset size &amp; other variables."
                button1Text="See Use Cases"
                :button1Link="usecaseLink"
                :galleryImages="VC_SOL_FIN_D"
              />
            </div>
          </div>
        </section>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'

// import VC_SOL_FIN_D1 from '../../../../static/img/solutionImages/VC_SOL_FIN_D1.png'
// import VC_SOL_FIN_D2 from '../../../../static/img/solutionImages/VC_SOL_FIN_D2.png'
// import VC_SOL_FIN_D3 from '../../../../static/img/solutionImages/VC_SOL_FIN_D3.png'
// import VC_SOL_FIN_D4 from '../../../../static/img/solutionImages/VC_SOL_FIN_D4.png'
// import VC_SOL_FIN_D5 from '../../../../static/img/solutionImages/VC_SOL_FIN_D5.png'
// import VC_SOL_FIN_D6 from '../../../../static/img/solutionImages/VC_SOL_FIN_D6.png'

export default {
  name: 'AboutStrategy',
  components: {
    PageCard,
    FeatureDetailsCard
  },
  data() {
    return {
      VC_SOL_FIN_D: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D6.png'
      ]
      // VC_SOL_FIN_D1: [
      //   VC_SOL_FIN_D1,
      //   VC_SOL_FIN_D2,
      //   VC_SOL_FIN_D3,
      //   VC_SOL_FIN_D4,
      //   VC_SOL_FIN_D5,
      //   VC_SOL_FIN_D6
      // ]
    }
  },
  created() {
    document.title = 'FIN Digital Channels'
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters('Authentication', ['isFiMenu']),
    usecaseLink() {
      return this.isFiMenu ? '/technology_analytics/about' : '/reports/about'
    }
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.homepage-content {
  background-size: cover;
}
</style>
